import { createApi } from '@reduxjs/toolkit/query/react';
import { ILoginResponse } from 'models/api/responses/authentication/ILoginResponse';
import { IDeviceDataExtended, IUser } from 'models/IUser';
import { ILanguage } from 'models/ILanguage';
import { ILiteralsResponse } from 'models/api/responses/literals/ILiteralsResponse';
import { ILiteral } from 'models/ILiteral';
import { ILanguagesResponse } from 'models/api/responses/languages/ILanguagesResponse';
import { IPostUserResponse } from 'models/api/responses/users/IPostUserResponse'
import { IGetUsersResponse } from 'models/api/responses/users/IGetUsersResponse'
import { IPostUserRequest } from 'models/api/requests/users/IPostUserRequest'
import { IPutUserRequest } from 'models/api/requests/users/IPutUserRequest'
import { baseQueryWithReauth } from './baseQueryWithReauth'
import { IGetSensorValuesReponse } from 'models/api/responses/sensors/IGetSensorValuesReponse';
import { IPatchLiteralRequest } from 'models/api/requests/literals/IPatchLiteralRequest';
import { IAnnotation } from 'models/IAnnotation';
import { IGetAnnotationsResponse } from 'models/api/responses/annotations/IGetAnnotationsResponse';
import { IGetAnnotationsRequest } from 'models/api/requests/annotations/IGetAnnotationsRequest';
import { IGetSensorValueRequest } from 'models/api/requests/sensorvalues/IGetSensorValueRequest';
import { IPostLiteralRequest} from 'models/api/requests/literals/IPostLiteralRequest';
import { IExternalDevice } from 'models/IExternalDevice';
import { IGetExternalDevicesRequest } from 'models/api/requests/externaldevices/IGetExternalDevicesRequest';
import { IPostExternalDeviceRequest } from 'models/api/requests/externaldevices/IPostExternalDeviceRequest';
import { IPatchExternalDeviceRequest } from 'models/api/requests/externaldevices/IPatchExternalDeviceRequest';
import { IGetExternalDevicesResponse } from 'models/api/responses/externaldevices/IGetExternalDevicesResponse';
import { IOrganization } from 'models/IOrganization';
import { IGetOrganizationsRequest } from 'models/api/requests/organizations/IGetOrganizationsRequest';
import { IGetOrganizationsResponse } from 'models/api/responses/organizations/IGetOrganizationsResponse';
import { IPostOrganizationRequest } from 'models/api/requests/organizations/IPostOrganizationRequest';
import { IPutOrganizationRequest } from 'models/api/requests/organizations/IPutOrganizationRequest';
import { INotification } from 'models/INotification';
import { IGetNotificationsRequest } from 'models/api/requests/notifications/IGetNotificationsRequest';
import { IGetNotificationsResponse } from 'models/api/responses/notifications/IGetNotificationsResponse';
import { IGetUserResponse } from 'models/api/responses/users/IGetUserResponse';
import { IPatchUserRequest } from 'models/api/requests/users/IPatchUserRequest';
import { IPatchNotificationRequest } from 'models/api/requests/notifications/IPatchNotificationRequest';
import { IPostAnnotationRequest } from 'models/api/requests/annotations/IPostAnnotationRequest';
import { IPostAnnotationResponse } from 'models/api/responses/annotations/IPostAnnotationResponse';
import { IGetNotificationsSummaryResponse } from 'models/api/responses/notifications/IGetNotificationsSummaryResponse';
import { INotificationsSummary } from 'models/INotificationsSummary';
import { IMarkNotificationsAsReadByClinicianRequest } from 'models/api/requests/notifications/IMarkNotificationsAsReadByClinicianRequest'
import { IGetUserNotificationsRequest } from 'models/api/requests/users/IGetUserNotificationsRequest';
import { IGetUserNotificationsResponse } from 'models/api/responses/users/IGetUserNotificationsResponse';
import { ILoginRequest } from 'models/api/requests/authentication/ILoginRequest';
import { IGetUserAnnotationsRequest } from 'models/api/requests/users/IGetUserAnnotationsRequest';
import { IGetUserAnnotationsResponse } from 'models/api/responses/users/IGetUserAnnotationsResponse';
import { IGetUserLastValuesResponse } from 'models/api/responses/users/IGetUserLastValuesResponse';
import { ISendRequestPasswordCodeRequest } from 'models/api/requests/authentication/ISendRequestPasswordCodeRequest';
import { IResetPasswordRequest } from 'models/api/requests/authentication/IResetPasswordRequest';
import { IChangePasswordRequest } from 'models/api/requests/authentication/IChangePasswordRequest';

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Literals', 'Users', 'ExternalDevices', 'Organizations', 'Notifications', 'Annotations'], // TODO improve caching
    endpoints: builder => ({
        //#region Auth
        login: builder.mutation<ILoginResponse, ILoginRequest>({
            query: requestData => ({
                url: '/auth/login',
                method: "POST",
                body: requestData
            })
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: '/auth/logout',
                method: "POST"
            })
        }),
        sendResetPasswordCode: builder.mutation<void, ISendRequestPasswordCodeRequest>({
            query: requestData => ({
                url: '/auth/sendresetpasswordcode',
                method: "POST",
                body: requestData
            })
        }),
        resetPassword: builder.mutation<void, IResetPasswordRequest>({
            query: requestData => ({
                url: '/auth/resetpassword',
                method: "POST",
                body: requestData
            })
        }),
        changePassword: builder.mutation<void, IChangePasswordRequest>({
            query: requestData => ({
                url: '/auth/changepassword',
                method: "POST",
                body: requestData
            })
        }),
        //#endregion
        
        //#region User
        getUsers: builder.query<IUser[], {parentId: string, role: number | null}>({
            query: requestData => ({
                url: `/users?parentId=${requestData.parentId}` + (requestData.role? `&role=${requestData.role}` : ""),
            }),
            transformResponse: (response: IGetUsersResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Users"]
        }),
        getUser: builder.query<IUser, string>({
            query: requestData => ({
                url: `/users/${requestData}`,
            }),
            transformResponse: (response: IGetUserResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Users"]
        }),
        postUser: builder.mutation<IPostUserResponse, IPostUserRequest>({
            query: requestData => ({
                url: '/users',
                method: 'POST',
                body: requestData
            }),
            invalidatesTags: () => ["Users"]
        }),
        putUser: builder.mutation<void, {userId: string, putUser: IPutUserRequest}>({
            query: requestData => ({
                url: `/users/${requestData.userId}`,
                method: 'PUT',
                body: requestData.putUser
            }),
            invalidatesTags: () => ["Users"]
        }),
        patchUser: builder.mutation<void, {userId: string, patchUser: IPatchUserRequest}>({
            query: requestData => ({
                url: `/users/${requestData.userId}`,
                method: 'PATCH',
                body: requestData.patchUser
            }),
            invalidatesTags: () => ["Users"]
        }),
        deleteUser: builder.mutation<void, string>({
            query: userId => ({
                url: `/users/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ["Users"]
        }),
        getUserNotifications: builder.query<INotification[], IGetUserNotificationsRequest>({
            query: requestData => {
                const queryParams = new URLSearchParams();

                if (requestData.from !== null) {
                    queryParams.set('from', requestData.from);
                }

                if (requestData.to !== null) {
                    queryParams.set('to', requestData.to);
                }

                if (requestData.readByClinician !== null) {
                    queryParams.set('readbyclinician', requestData.readByClinician.toString());
                }

                return {
                    url: `/users/${requestData.userId}/notifications?${queryParams.toString()}`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetUserNotificationsResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Notifications"]
        }),
        markAllUserNotificationsAsReadByClinician: builder.mutation<void, string>({
            query: userId => ({
                url: `/users/${userId}/markallnotificationsasreadbyclinician`,
                method: 'POST'
            }),
            invalidatesTags: () => ["Notifications"]
        }),
        getUserAnnotations: builder.query<IAnnotation[], IGetUserAnnotationsRequest>({
            query: requestData => {
                const queryParams = new URLSearchParams();

                if (requestData.from !== null) {
                    queryParams.set('from', requestData.from);
                }

                if (requestData.to !== null) {
                    queryParams.set('to', requestData.to);
                }

                return {
                    url: `/users/${requestData.userId}/annotations?${queryParams.toString()}`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetUserAnnotationsResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Notifications"]
        }),
        getUserLastValues: builder.query<IDeviceDataExtended[], string>({
            query: requestData => ({
                url: `/users/${requestData}/lastvalues`,
            }),
            transformResponse: (response: IGetUserLastValuesResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Users"]
        }),
        getUserParent: builder.query<IUser, string>({
            query: requestData => ({
                url: `/users/${requestData}/parent`,
            }),
            transformResponse: (response: IGetUserResponse, meta, arg) => response.data,
            providesTags: (result, error, args) =>  ["Users"]
        }),
        //#endregion

        //#region Languages
        getLanguages: builder.query<ILanguage[], void>({
            query: () => {
                return {
                    url: "/languages",
                    method: "GET"
                }
            },
            transformResponse: (response: ILanguagesResponse) => response.data ?? []
            // TODO cache this
        }),
        //#endregion

        //#region Literals
        getLiterals: builder.query<ILiteral[], { languageId: string }>({
            query: (requestData) => {
                return {
                    url: `/literals?isocode=${requestData.languageId}`,
                    method: "GET"
                }
            },
            transformResponse: (response: ILiteralsResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["Literals"]
        }),
        postLiteral: builder.mutation<void, {postLiteral: IPostLiteralRequest}>({
            query: requestData => ({
                url: `/literals`,
                method: 'POST',
                body: requestData.postLiteral
            }),
            invalidatesTags: () => ["Literals"]
        }),
        patchLiteral: builder.mutation<void, {literalId: number, patchLiteral: IPatchLiteralRequest}>({
            query: requestData => ({
                url: `/literals/${requestData.literalId}`,
                method: 'PATCH',
                body: requestData.patchLiteral
            }),
            invalidatesTags: () => ["Literals"]
        }),
        deleteLiteral: builder.mutation<void, {literalId: number}>({
            query: requestData => ({
                url: `/literals/${requestData.literalId}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ["Literals"]
        }),
        //#endregion

        //#region Sensor values
        getSensorValues: builder.mutation<IGetSensorValuesReponse, IGetSensorValueRequest>({
            query: (requestData) => {
                return {
                    url: `/sensors/${requestData.sensorId}/values?from=${requestData.from}&to=${requestData.to}`,
                    method: "GET"
                }
            }
        }),
        //#endregion

        //#region Annotations
        getAnnotations: builder.query<IAnnotation[], IGetAnnotationsRequest>({
            query: (requestData) => {
                return {
                    url: `/annotations?userId=${requestData.userId}&from=${requestData.from}&to=${requestData.to}`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetAnnotationsResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["Annotations"]
        }),
        postAnnotation: builder.mutation<IPostAnnotationResponse, IPostAnnotationRequest>({
            query: requestData => ({
                url: '/annotations',
                method: 'POST',
                body: requestData
            }),
            invalidatesTags: () => ["Annotations"]
        }),
        //#endregion

        //#region External devices
        getExternalDevices: builder.query<IExternalDevice[], IGetExternalDevicesRequest>({
            query: () => {
                return {
                    url: `/externaldevices`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetExternalDevicesResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["ExternalDevices"]
        }),
        postExternalDevice: builder.mutation<void, IPostExternalDeviceRequest>({
            query: requestData => ({
                url: `/externaldevices`,
                method: 'POST',
                body: requestData
            }),
            invalidatesTags: () => ["ExternalDevices"]
        }),
        patchExternalDevice: builder.mutation<void, {externalDeviceId: string, patchExternalDevice: IPatchExternalDeviceRequest}>({
            query: requestData => ({
                url: `/externaldevices/${requestData.externalDeviceId}`,
                method: 'PATCH',
                body: requestData.patchExternalDevice
            }),
            invalidatesTags: () => ["ExternalDevices"]
        }),
        deleteExternalDevice: builder.mutation<void, string>({
            query: requestData => ({
                url: `/externaldevices/${requestData}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ["ExternalDevices"]
        }),
        //#endregion

        //#region Organizations
        getOrganizations: builder.query<IOrganization[], IGetOrganizationsRequest>({
            query: () => {
                return {
                    url: `/organizations`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetOrganizationsResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["Organizations"]
        }),
        postOrganization: builder.mutation<void, {postOrganization: IPostOrganizationRequest}>({
            query: requestData => ({
                url: `/organizations`,
                method: 'POST',
                body: requestData.postOrganization
            }),
            invalidatesTags: () => ["Organizations"]
        }),
        putOrganization: builder.mutation<void, {organizationId: number, putOrganization: IPutOrganizationRequest}>({
            query: requestData => ({
                url: `/organizations/${requestData.organizationId}`,
                method: 'PUT',
                body: requestData.putOrganization
            }),
            invalidatesTags: () => ["Organizations"]
        }),
        deleteOrganization: builder.mutation<void, {organizationId: string}>({
            query: requestData => ({
                url: `/organizations/${requestData.organizationId}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => ["Organizations"]
        }),
        //#endregion
    
        //#region Notifications
        getNotifications: builder.query<INotification[], IGetNotificationsRequest>({
            query: (requestData) => {
                const queryParams = new URLSearchParams();

                if (requestData.from !== null) {
                    queryParams.set('from', requestData.from);
                }

                if (requestData.to !== null) {
                    queryParams.set('to', requestData.to);
                }

                if (requestData.readByClinician !== null) {
                    queryParams.set('readbyclinician', requestData.readByClinician.toString());
                }

                return {
                    url: `/notifications?${queryParams.toString()}`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetNotificationsResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["Notifications"]
            // TODO cache this
        }),
        patchNotification: builder.mutation<void, {notificationId: string, patchNotification: IPatchNotificationRequest}>({
            query: requestData => ({
                url: `/notifications/${requestData.notificationId}`,
                method: 'PATCH',
                body: requestData.patchNotification
            }),
            invalidatesTags: () => ["Notifications"]
        }),
        markAllNotificationsAsReadByClinician: builder.mutation<void, void>({
            query: () => ({
                url: `/notifications/markallasreadbyclinician`,
                method: 'POST'
            }),
            invalidatesTags: () => ["Notifications"]
        }),
        markNotificationsAsReadByClinician: builder.mutation<void, {markNotificationsAsReadByClinician: IMarkNotificationsAsReadByClinicianRequest}>({
            query: (requestData) => ({
                url: `/notifications/markasreadbyclinician`,
                method: 'POST',
                body: requestData.markNotificationsAsReadByClinician
            }),
            invalidatesTags: () => ["Notifications"]
        }),
        getNotificationsSummary: builder.query<INotificationsSummary, void>({
            query: () => {
                return {
                    url: `/notifications/summary`,
                    method: "GET"
                }
            },
            transformResponse: (response: IGetNotificationsSummaryResponse) => response.data ?? [],
            providesTags: (result, error, args) =>  ["Notifications"]
            // TODO cache this
        })
        //#endregion
    })
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useSendResetPasswordCodeMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,

    usePostUserMutation,
    usePutUserMutation,
    usePatchUserMutation,
    useGetUsersQuery,
    useGetUserQuery,
    useDeleteUserMutation,
    useGetUserNotificationsQuery,
    useMarkAllUserNotificationsAsReadByClinicianMutation,
    useGetUserAnnotationsQuery,
    useGetUserLastValuesQuery,
    useGetUserParentQuery,

    useGetLanguagesQuery,

    useGetLiteralsQuery,
    usePostLiteralMutation,
    usePatchLiteralMutation,
    useDeleteLiteralMutation,

    useGetSensorValuesMutation,

    useGetAnnotationsQuery,
    usePostAnnotationMutation,

    useGetExternalDevicesQuery,
    usePostExternalDeviceMutation,
    usePatchExternalDeviceMutation,
    useDeleteExternalDeviceMutation,

    useGetOrganizationsQuery,
    usePostOrganizationMutation,
    useDeleteOrganizationMutation,
    usePutOrganizationMutation,

    useGetNotificationsQuery,
    usePatchNotificationMutation,
    useMarkAllNotificationsAsReadByClinicianMutation,
    useMarkNotificationsAsReadByClinicianMutation,
    useGetNotificationsSummaryQuery,

} = apiSlice