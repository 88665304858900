import { ReactNode, useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, Theme, TypographyVariantsOptions } from '@mui/material/styles';

// project import
import useConfig from 'hooks/config/useConfig';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';

// types
import { CustomShadowProps } from 'types/theme';
import ClientContext, { IClient } from 'hooks/client/ClientContext';
import useProvideClient from 'hooks/client/useProvideClient';
import { PresetColor } from 'types/config';
import { UserProviderId } from 'models/IUser';

// types
type ThemeCustomizationProps = {
  children: ReactNode;
};

const getPresetColor: (client: IClient) => PresetColor = (client: IClient) => {
  switch (client.provider) {
    case UserProviderId.CHAMP:
      return "CHAMPTheme";
    case UserProviderId.KORE:
      return "KORETheme";
    case UserProviderId.AIQReady:
      return "AIQReadyTheme";
    case UserProviderId.SMILE:
      return "SMILETheme";
    case UserProviderId.AURA:
      return "AURATheme";
    default:
      return "CHAMPTheme";
  }
};

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
  const clientProvider = useProvideClient();

  const { themeDirection, mode, fontFamily } = useConfig();

  const theme: Theme = useMemo<Theme>(() => Palette(mode, getPresetColor(clientProvider.client)), [mode, clientProvider.client]);

  const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
    () => Typography(mode, fontFamily, theme),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, fontFamily]
  );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme]);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <ClientContext.Provider value={clientProvider}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ClientContext.Provider>
  );
}
